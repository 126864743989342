<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-4 col-md-12">
        <div class="welcome-text">
          <h1>{{ $t('reset-password.title') }}</h1>
        </div>
        <div class="w-100">
          <div v-if="EmailSended" class="description">
            <h1 style="font-size: 20px">
              {{ $t('reset-password.email-sended-new', { email: email }) }}
            </h1>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 py-5 text-right">
        <img src="/images/signin-bg.svg" width="610" style="max-width: 100%;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      email: '',
      EmailSended: false
    }
  },
  methods: {
    sendConfirmation(iin) {
      this.$http.get(window.API_ROOT + '/api/password/reset/email', {
        params: {
          iin: iin,
          is_teacher: 0
        }
      })
          .then((res) => {
            this.EmailSended = true;
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
    }
  },
  mounted() {
    if (localStorage.getItem('user-recovery')) {
      const data = JSON.parse(localStorage.getItem('user-recovery'));
      this.email = data.email;
      this.sendConfirmation(data.iin);
    }
  }
}
</script>
